// import react and react router dom
import { useContext, useRef } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// import assets
import logo from "../../assets/KnowScam_black.svg";

// import styles
import classes from "./styles/autoScan.module.css";

// import contexts
import LanguageContext from "../../contexts/LanguageContext";
import GoogleButton from "../../components/google-button";

function AutoScanModal({ open, handleClose }) {
  const jwtToken = localStorage.getItem("idToken");
  const formRef = useRef(null);
  const authUrl = process.env.REACT_APP_GOOGLE_AUTH_URL || "";

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const welcomeToString =
    language === "en_US" ? "Welcome to Scamnetic" : "Bienvenue sur Scamnetic";
  const toSetupString =
    language === "en_US"
      ? "To set up AutoScan, Sign in with your Google Account"
      : "Pour configurer Analyse automatique, connectez-vous avec votre compte Google";
  const skipThisString =
    language === "en_US" ? "Skip this step" : "Passer cette étape";

  function performGoogleOAuth() {
    formRef.current.submit();
  }

  return (
    <div>
      <form ref={formRef} action={authUrl} method="post" noValidate>
        <input type="hidden" name="token" value={jwtToken} />
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="50px 48px"
        >
          <img
            src={logo}
            alt="logo"
            style={{ textAlign: "center" }}
            width="100%"
            height="39px"
          />
          <DialogTitle id="form-dialog-title">
            <Typography
              fontSize="20px"
              fontWeight="bold"
              style={{ color: "#121217", textAlign: "center" }}
              padding="20px 10px"
            >
              {welcomeToString}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                style={{ color: "#121217", textAlign: "center" }}
                paddingBottom="30px"
              >
                {toSetupString}
              </Typography>

              <GoogleButton onClick={performGoogleOAuth} />
              <Link
                as="button"
                className={classes.autoScan}
                onClick={handleClose}
              >
                {skipThisString}
              </Link>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

export default AutoScanModal;
