import {useCallback, useEffect, useState} from "react";

async function checkIfImageExists(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (data) => {
      // console.log(data);
      return resolve(true);
    };

    img.onerror = (err) => {
      // console.log("Could not load " + url, { err });
      return resolve(false);
    };
    img.src = url;
  });
}

const useAvatar = ({ userName }) => {
  const [avatar, setAvatar] = useState(undefined);

  async function loadAvatar(userName) {
    let exists = await checkIfImageExists(
      process.env.REACT_APP_ASSETS_URL + `/avatars/${userName}.jpg`
    );
    if (!exists) {
      exists = await checkIfImageExists(
        process.env.REACT_APP_ASSETS_URL + `/avatars/${userName}.png`
      );
      if (!exists) {
        setAvatar(undefined);
      } else {
        setAvatar(
          process.env.REACT_APP_ASSETS_URL +
            `/avatars/${userName}.png?ver=${new Date().toISOString()}`
        );
      }
    } else {
      setAvatar(
        process.env.REACT_APP_ASSETS_URL +
          `/avatars/${userName}.jpg?ver=${new Date().toISOString()}`
      );
    }
  }

  useEffect(() => {
    if (userName) {
      loadAvatar(userName);
    }
  }, [userName]);

  const reloadAvatar = useCallback((userName) => {
    loadAvatar(userName);
  }, []);

  return {
    avatar,
    reloadAvatar,
  };
};

export default useAvatar;
