import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./ResourceCenter.css";

const ResourceCenterCard = ({ card }) => {
  return (
    <Box display="flex" className="card">
      <Box sx={{ overflow: "visible" }}>
        <img
          src={card?.icon?.url}
          alt={card?.iconAltText}
          className="cardIcon"
        />
      </Box>

      <Box>
        <Box as="h2" className="cardTitle">
          {card?.title}
        </Box>
        <Box as="p" className="cardDescription">
          {card?.description}
        </Box>
        <Link to={card?.linkHref} className="cardLink">
          <span className="cardLinkText">
            {card?.linkText}
            <ArrowForwardIcon fontSize="small" />
          </span>
        </Link>
      </Box>
    </Box>
  );
};

export default ResourceCenterCard;
