import {AppBar, Button, Container, MenuItem, Select, Toolbar,} from "@mui/material";
import {useEffect, useState} from "react";
import CompanyLogo from "../assets/KnowScam_white.svg";

const LanguageSelect = ({ setLanguageSelected }) => {
  // Check local storage for the selected language or default to "en_US"
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage ? storedLanguage : "en_US";
  });

  useEffect(() => {
    // Update local storage whenever the language state changes
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="x2">
          <Toolbar disableGutters>
            <img src={CompanyLogo} alt="Scamnetic" height="35px" />
          </Toolbar>
        </Container>
      </AppBar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          marginTop: "100px",
        }}
      >
        <h2
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          Please select a language
        </h2>
        <Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          sx={{
            width: "300px",
          }}
        >
          <MenuItem value={"en_US"}>English (US)</MenuItem>
          <MenuItem value={"fr_CA"}>Français</MenuItem>
        </Select>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#F2800A",
            "&:hover": { bgcolor: "#000" },
            width: "400px",
            height: "51px",
          }}
          onClick={() => {
            setLanguageSelected(language);
          }}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default LanguageSelect;
