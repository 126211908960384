import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAdminPortalTranslation } from "../../api/translationApi";

const ConfirmInvite = ({
  goBack = () => {},
  formData,
  submitInvite = () => {},
}) => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  return (
    <>
      <div
        style={{
          padding: 40,
        }}
      >
        <div style={{ width: "40vw" }} />
        <h2
          style={{
            fontWeight: "500",
          }}
        >
          {adminPortalTranslation?.reviewConfirm}
        </h2>
        <p
          style={{
            fontWeight: "500",
              lineHeight: "10px",
          }}
        >
          <b>2 Adults + 2 Kids</b>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontWeight: "500",
                lineHeight: "10px",
                marginTop: "3em",
            }}
          >
            {adminPortalTranslation?.recipientInfo}
          </h2>
          <div>
            <p
              style={{
                fontWeight: "700",
                  lineHeight: "10px",
              }}
            >
              {adminPortalTranslation?.business_header_name}
            </p>
            <p
              style={{
                fontWeight: "100",
                  lineHeight: "10px",
              }}
            >
              {formData?.firstName} {formData?.lastName}
            </p>
          </div>
          <div>
            <p
              style={{
                fontWeight: "700",
                  lineHeight: "10px",
              }}
            >
              {adminPortalTranslation?.email}
            </p>
            <p
              style={{
                fontWeight: "100",
                  lineHeight: "10px",
              }}
            >
              {formData?.email || "There was an issue reading email"}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 2, marginTop: "100px" }}
              type="submit"
              onClick={goBack}
            >
              {adminPortalTranslation?.back_button}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 2, marginTop: "100px" }}
              type="submit"
              onClick={submitInvite}
            >
              {adminPortalTranslation?.confirm_button}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmInvite;
