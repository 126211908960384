import {Box, Button, Typography} from "@mui/material";
import styles from "./IndustryNews.module.css";

const IndustryNewsCard = ({ card }) => {
  return (
    <>
      <Box className={styles.industryNewsCard}>
        <Box className={styles.industryNewsCard__top}>
          <img
            src={card.icon.url}
            alt={card.icon.title}
            width="39px"
            height="auto"
          />
        </Box>
        <Box className={styles.industryNewsCard__bottom}>
          <Typography className={styles.industryNewsCard__heading}>
            {card.heading}
          </Typography>
          <Typography paragraph>{card.description}</Typography>
          <Button fullWidth className={styles.button} href={card.buttonLink}>
            {card.buttonText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default IndustryNewsCard;
