import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import Title from "../title/Title";
import classes from "./BusinessForm.module.css";
import UserContext from "../../contexts/UserContexts";
import FormField from "../form-field";
import SubscriptionPlan from "../subscription-plan";
import PhoneField from "../phone-field/PhoneField";
import UploadImage from "../upload-image";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import ProductList from "../../pages/product-list";

const BusinessForm = ({ method, isRetail, translation, businessData }) => {
  const { control, handleSubmit, reset, register } = useForm();
  const [open, setOpen] = useState(false);
  const { jwtToken, userGroupPrefix } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { selectedItem } = location.state || {};
  const [business, setBusiness] = useState(businessData || selectedItem);
  const editForm = method === "PUT";
  const { t } = useInternalTranslation();
  const [status] = useState(() => {
    if (editForm) {
      return business?.status;
    } else {
      return "Active";
    }
  });
  const [product, setProduct] = useState(() => {
    if (editForm) {
      let productDetails = business?.productDetails;
      if (typeof productDetails === "string")
        productDetails = JSON.parse(productDetails);

      return productDetails[0]?.id;
    } else {
      return "";
    }
  });
  const [hasSubdomain, setHasSubdomain] = useState(!!business?.subdomainName);
  const [previewURL, setPreviewURL] = useState("");
  const [refresh, setRefresh] = useState(true);
  const productList = business?.productDetails;

  const isBusAdmin = userGroupPrefix === "BusinessAdmin";

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const businessLogoString =
    language === "en_US" ? "Business Logo" : "Logo de l'entreprise";

  const selectedBusinessId = business?.id;
  const primaryContact = business?.primaryContact;

  const url = editForm
    ? `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${selectedBusinessId}`
    : `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses`;

  const onSubmit = async (data) => {
    const formData = {
      name: String(data.business_name),
      primaryContact: {
        firstName: String(data.firstName),
        lastName: String(data.lastName),
        phoneNumber: String(data.phoneNumber),
        email: String(data.email),
        ...(editForm ? { id: business?.id } : {}),
      },
      subdomainName: String(data.subdomainName),
      productId: String(product),
      ...(editForm ? { status: status } : {}),
    };

    try {
      const response = await submitForm(formData);
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(`${t("Failed to submit form")}: ${errorData.message}`);
      } else {
        if (!editForm) {
          reset();
          setProduct("");
        } else {
          if (!!data.subdomainName) setHasSubdomain(data.subdomainName);
        }
        setOpen(true);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      setErrorMessage(t("Failed to submit form"));
    }

    setRefresh(true);
  };

  const submitForm = async (formData) => {
    const options = {
      method: method,
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    return fetch(url, options);
  };

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setErrorMessage(null);
    }, 250);
  }

  useEffect(() => {
    if (refresh) {
      async function imageUrlExists(url) {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            return resolve(true);
          };
          img.onerror = () => {
            return resolve(false);
          };
          img.src = url;
        });
      }

      async function fetchLogo() {
        let url = `${
          process.env.REACT_APP_ASSETS_URL
        }/businesses/logos/${business?.subdomainName?.toLowerCase()}`;

        if (
          await imageUrlExists(`${url}.png?ver=${new Date().toISOString()}`)
        ) {
          setPreviewURL(`${url}.png?ver=${new Date().toISOString()}`);
        } else if (
          await imageUrlExists(`${url}.jpg?ver=${new Date().toISOString()}`)
        ) {
          setPreviewURL(`${url}.jpg?ver=${new Date().toISOString()}`);
        }
      }

      if (!!business?.subdomainName) fetchLogo();

      setRefresh(false);
    }
  }, [refresh]);

  const action = (
    <>
      &nbsp; &nbsp; &nbsp;
      <Button
        color="secondary"
        size="small"
        sx={{
          color: "white",
        }}
        component={Link}
        to={`/businesses`}
      >
        <u>{t(`Back to List`)}</u>
      </Button>
    </>
  );

  return (
    <>
      <Snackbar
        open={open || !!errorMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={!errorMessage ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {!!errorMessage && errorMessage}
          {!errorMessage && editForm && translation["success_update_business"]}
          {!errorMessage && !editForm && translation["success_create_business"]}
          {!errorMessage && action}
        </Alert>
      </Snackbar>

      {method === "POST" ? (
        <Title>{translation.add_business_section_header}</Title>
      ) : (
        <Title>{translation["business_business_details"]}</Title>
      )}
      <FormProvider>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Grid container columnSpacing={8}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <FormField
                  gridSize={12}
                  control={control}
                  label={translation.business_business_name}
                  name="business_name"
                  defaultValue={business ? business?.name : ""}
                  rules={{ required: t("Business Name is required") }}
                />
                <FormField
                  gridSize={6}
                  control={control}
                  label={translation.business_first_name}
                  name="firstName"
                  defaultValue={business ? primaryContact?.firstName : ""}
                  rules={{ required: t("First Name is required") }}
                />
                <FormField
                  gridSize={6}
                  control={control}
                  label={translation.business_last_name}
                  name="lastName"
                  defaultValue={business ? primaryContact?.lastName : ""}
                  rules={{ required: t("Last Name is required") }}
                />

                <PhoneField
                  gridSize={12}
                  control={control}
                  label={translation.business_phone_number}
                  name="phoneNumber"
                  defaultValue={business ? primaryContact?.phoneNumber : ""}
                />

                <FormField
                  gridSize={12}
                  control={control}
                  label={translation.business_email_address}
                  name="email"
                  defaultValue={business ? primaryContact?.email : ""}
                  rules={{
                    required: t("Email is required"),
                    validate: (value) => {
                      const emailPattern =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      return (
                        emailPattern.test(value) ||
                        t("Email has improper format")
                      );
                    },
                  }}
                />

                <Grid item xs={12}>
                  {!isRetail && (
                    <SubscriptionPlan
                      planType={business}
                      onChange={handleProductChange}
                      register={register("productId")}
                      translation={translation}
                      disabled={isBusAdmin}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormField
                    gridSize={12}
                    control={control}
                    disabled={isBusAdmin}
                    label={translation.sub_domain_name}
                    name="subdomainName"
                    defaultValue={business ? business?.subdomainName : ""}
                    onChange={(e) => {
                      if (!editForm) return;

                      setBusiness({
                        ...business,
                        subdomainName: e.target.value,
                      });

                      if (hasSubdomain && !e.target.value) {
                        setHasSubdomain(false);
                      }
                    }}
                    required={false}
                    rules={{
                      validate: (value) => {
                        const subdomainNamePattern =
                          /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)?$/;
                        return (
                          subdomainNamePattern.test(value) ||
                          t("Sub domain has improper format")
                        );
                      },
                    }}
                  />
                </Grid>

                {hasSubdomain && (
                  <Grid item xs={12}>
                    <>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" xs={{ mt: 2 }}>
                          {businessLogoString}
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="column" gap={3}>
                        <Box display="flex" flexDirection="row">
                          {!!previewURL && (
                            <img
                              alt={"Preview URL"}
                              src={previewURL}
                              style={{
                                maxWidth: "125px",
                                maxHeight: "125px",
                              }}
                            />
                          )}
                        </Box>
                        {!isBusAdmin && (
                          <>
                            <UploadImage
                              setPreview={setPreviewURL}
                              translation={translation}
                              disabled={!isBusAdmin}
                            />
                            <Typography variant="body1">
                              {t(
                                "Please upload a PNG or JPEG with a maximum size of 800x800 pixels"
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isRetail && (
              <Grid item xs={12} marginTop={6}>
                <ProductList productList={productList} />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={3}>
              <Button
                color="tertiary"
                component={Link}
                type="button"
                to="/businesses"
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
              >
                {translation.button_cancel}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
                type="submit"
              >
                {translation.button_save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default BusinessForm;
