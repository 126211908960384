import {useContext, useEffect, useState} from "react";
import UserContext from "../../contexts/UserContexts";
import {getMemberDetails} from "../../api/memberApi";
import {Dialog} from "@mui/material";
import AcceptanceStepper from "./AcceptanceStepper";

const useFirstTimeLogin = () => {

    const [open, setOpen] = useState(false);
    const { jwtToken } = useContext(UserContext);
    const [defaultStep, setDefaultStep] = useState(0);

    const onComplete = () => {
        setOpen(false);
    }

    useEffect(() => {
        (async () => {
            if (jwtToken) {
                const memberDetails = await getMemberDetails(jwtToken, true);

                if (memberDetails?.data?.memberType === "ME") {
                    if (!open && (!memberDetails?.data?.acceptPrivacyPolicy || !memberDetails?.data?.acceptPledge)) {
                        if (!memberDetails?.data?.acceptPrivacyPolicy) {
                            setDefaultStep(0)
                        } else if (!memberDetails?.data?.acceptPledge) {
                            setDefaultStep(1);
                        }
                        setOpen(true);
                    } else {
                        setOpen(false);
                    }
                }

            }
        })();
    }, []);

    function FirstTimeLoginModal() {
        return (
            <Dialog open={open} style={{ width: "70%", margin: "auto" }} maxWidth="lg">
                <AcceptanceStepper jwtToken={jwtToken} defaultStep={defaultStep} onClose={onComplete} />
            </Dialog>
        )
    }

    function show() {
        setOpen(true);
    }

    return {
        FirstTimeLoginModal,
        show
    }
}

export default useFirstTimeLogin;