import { Dialog } from "@mui/material";
import { useState } from "react";
import InviteMember from "./InviteMember";
import ConfirmInvite from "./ConfirmInvite";
import useSnackBar from "../../components/snack-bar/useSnackBar.js";

export const useInviteMember = () => {
  const [open, setOpen] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [formData, setFormData] = useState({});

  const { show: showSnackbar, CustomSnackBar, onClosing } = useSnackBar();

  function show() {
    onClosing();
    setOpen(true);
  }

  function hide() {
    setOpen(false);
    setFormComplete(false);
    setFormData({});
  }

  function handleSubmit() {
    hide();
    showSnackbar("Invitation successfully sent!");
  }

  function InviteMemberPrompt() {
    return (
      <>
        <CustomSnackBar />
        <Dialog open={open} onClose={hide}>
          {!formComplete ? (
            <InviteMember
              goBack={hide}
              formData={formData}
              setFormData={setFormData}
              setFormComplete={setFormComplete}
            />
          ) : (
            <ConfirmInvite
              goBack={() => setFormComplete(false)}
              formData={formData}
              submitInvite={handleSubmit}
            />
          )}
        </Dialog>
      </>
    );
  }

  return { show, hide, InviteMemberPrompt };
};
