// import material UI
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Box, Button, CircularProgress, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {Delete} from "@mui/icons-material";

// import styles
import "./AddressTable.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddressTable = ({
  headers,
  whitelist,
  columns,
  isLoading,
  error,
  filterText,
  onDelete,
  onEdit,
}) => {
  if (error) {
    return (
      <Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        sx={{ my: 10 }}
      >
        <h1>Something went wrong. Please try again later.</h1>
      </Box>
    );
  }

  const filteredDomains = whitelist.filter((item) =>
    item.domain.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      {isLoading ? (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, indx) => (
                  <StyledTableCell key={header + indx}>
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            sx={{ my: 10 }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, idx) => (
                  <StyledTableCell
                    key={header + idx}
                    style={
                      idx === headers.length - 1 ? { textAlign: "right" } : {}
                    }
                  >
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDomains.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={headers.length}>
                    {`No matches found.`}
                  </StyledTableCell>
                </TableRow>
              ) : (
                filteredDomains.map((item, idx) => (
                  <StyledTableRow key={idx}>
                    {columns.map((column, index) => (
                      <StyledTableCell key={index}>
                        {column.dataField(item)}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Tooltip title="Edit">
                        <Button
                          className="icon"
                          state={{ selectedItem: item }}
                          style={{ padding: "4px" }}
                          onClick={() => onEdit(item)}
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <Button
                          className="icon"
                          state={{ selectedItem: item }}
                          style={{ padding: "4px" }}
                          onClick={() => onDelete(item.domain)}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default AddressTable;
