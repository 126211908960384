import React, {useContext, useState} from "react";
import {Button, Grid} from "@mui/material";
import CustomerDetails from "../../components/profile/partials/customer-details";
import {UserContext} from "../../contexts/UserContext";
import ProductDetails from "../../components/profile/partials/product-details";
import Settings from "../../components/profile/partials/settings";

import {useAdminPortalTranslation} from "../../api/translationApi";

const Profile = () => {
  const { loading, userGroups } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("profile");

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  if (loading) {
    return <div>Loading...</div>;
  }

  const buttonStyle = (tab) => ({
    fontWeight: activeTab === tab ? "bold" : "normal",
  });

  function canView() {
    if (userGroups.length === 0) return true;
    if (userGroups.includes("Admin")) return true;

    return false;
  }

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      <Grid container spacing={4} marginBottom="32px">
        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={() => setActiveTab("profile")}
            style={{ ...buttonStyle("profile"), textTransform: "none" }}
          >
            {adminPortalTranslation.profile_header}
          </Button>
          {canView() && (
            <Button
              onClick={() => setActiveTab("subscription")}
              style={{ ...buttonStyle("subscription"), textTransform: "none" }}
            >
              {adminPortalTranslation.subscription_header}
            </Button>
          )}
          <Button
            onClick={() => setActiveTab("settings")}
            style={{ ...buttonStyle("settings"), textTransform: "none" }}
          >
            {adminPortalTranslation.settings}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {activeTab === "profile" && (
            <CustomerDetails translation={adminPortalTranslation} />
          )}
          {activeTab === "subscription" && (
            <ProductDetails translation={adminPortalTranslation} />
          )}
          {activeTab === "settings" && (
            <Settings translation={adminPortalTranslation} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
