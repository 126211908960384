import React from "react";
import Typography from "@mui/material/Typography";

function Title({ children }) {
  return (
    <Typography
      component="h1"
      color="primary"
      sx={{ fontSize: "36px", color: "#020D0F" }}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

export default Title;
