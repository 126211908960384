import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// Will come from API
const resources = {
  en_US: {
    translation: {
      welcome: "Welcome",
      privacyPolicy: "Privacy Policy",
      termsOfService: "Terms of Service",
    },
  },
  fr_CA: {
    translation: {
      welcome: "Bienvenue",
      privacyPolicy: "Politique de confidentialité",
      termsOfService: "Conditions d'utilisation",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
