import {useEffect, useState} from "react";
import {createTheme} from "@mui/material/styles";

async function checkIfImageExists(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = (data) => {
            // console.log(data);
            return resolve(true);
        };

        img.onerror = (err) => {
            // console.log("Could not load " + url, { err });
            return resolve(false);
        };
        img.src = url;
    });
}

const customTheme = createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 70,
                    '@media (min-width:600px)': {
                        minHeight: 80,  // Override height for larger screens
                    },
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',  // Apply orange background globally
                }
            }
        }
    },

    palette: {
        primary: {
            main: "rgb(255,255,255)",
        },
        secondary: {
            main: "#F2800A",
        },
    },
});

const scamneticTheme = createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 70,
                    '@media (min-width:600px)': {
                        minHeight: 80,  // Override height for larger screens
                    },
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#000',  // Apply orange background globally
                }
            }
        }
    },
    palette: {
        primary: {
            main: "rgb(0,0,0)",
        },
        secondary: {
            main: "#F2800A",
        },
    },
});

const useCobrand = () => {
    const [logoLoaded, setLogoLoaded] = useState(false);
    const [customLogo, setCustomLogo] = useState(undefined);
    const [theme, setTheme] = useState(customTheme);
    const [themeStyles, setThemeStyles] = useState("custom");

    useEffect(() => {
        async function loadLogo() {
            // Get the Subdomain
            let hostName = window.location.hostname;
            let parts = hostName.split('.');
            let exists = await checkIfImageExists(process.env.REACT_APP_ASSETS_URL + `/businesses/logos/${parts[0]}.jpg`);
            if (!exists) {
                exists = await checkIfImageExists(process.env.REACT_APP_ASSETS_URL + `/businesses/logos/${parts[0]}.png`)
                if (!exists) {
                    setCustomLogo(undefined);
                    setTheme(scamneticTheme);
                    setThemeStyles("scamnetic");
                } else {
                    setCustomLogo(process.env.REACT_APP_ASSETS_URL + `/businesses/logos/${parts[0]}.png?ver=${new Date().toISOString()}`);
                }
            } else {
                setCustomLogo(process.env.REACT_APP_ASSETS_URL + `/businesses/logos/${parts[0]}.jpg?ver=${new Date().toISOString()}`);
            }
            setLogoLoaded(true);
        }

        loadLogo()
    }, [])

    return {
        customLogo,
        setCustomLogo,
        theme,
        themeStyles,
        logoLoaded,
    }
}

export default useCobrand;