import {Button, DialogActions, DialogContent, Typography} from "@mui/material";
import {useAdminPortalTranslation} from "../../api/translationApi";
import {useContext, useState} from "react";
import pledge from "./constants/pledge.txt";
import pledgeFR from "./constants/pledgeFR.txt";
import LanguageContext from "../../contexts/LanguageContext";

const usePledge = (jwtToken, onAccept = (response) => {}, onDecline = () => {}) => {

  const [pledgeText, setPledgeText] = useState("");
  const { adminPortalTranslation } = useAdminPortalTranslation();
  const {language} = useContext(LanguageContext);

  async function loadContent() {
    if (!pledgeText && language) {
      const content = language === "en_US" ? pledge : pledgeFR;

      const response = await fetch(content)
      if (response.status === 200) {
        const text = await response.text();
        setPledgeText(text);
      }
    }
  }

  async function accept() {
    const url = `${process.env["REACT_APP_API_URL"]}/members/pledge`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + jwtToken,

      },
      body: JSON.stringify({ accepted: "true" }),
    });

    if (response.status === 200) {
      if (onAccept) {
        onAccept("FixMe");
      }
    }
  }

  const formatTextToHTML = (text) => {
    // Replace newlines with <br> tags
    let formattedText = text.replace(/\n\s*\n/g, "</p><p>");
    formattedText = formattedText.replace(/\n/g, "<br>");

    // Replace bullet points with <ul> and <li> tags
    formattedText = formattedText
        .replace(/·\s+/g, "<li>")
        .replace(/<\/p><p>/g, "</li></ul><p>");
    formattedText = "<p>" + formattedText + "</p>";
    formattedText = formattedText
        .replace(/<p><li>/g, "<ul><li>")
        .replace(/<\/li><\/p>/g, "</li></ul>");

    return formattedText;
  };

  function Pledge() {
    if (pledgeText) {
      return (
          <>
            <DialogContent>
              <Typography
                  className="pledge-text"
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: formatTextToHTML(pledgeText) }}
              />
            </DialogContent>
            <DialogActions>
              {/*onClick={onSignOut}*/}
              <Button variant="text" color="primary" onClick={onDecline}>
                {adminPortalTranslation?.button_decline || "Decline"}
              </Button>
              <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  onClick={accept}
              >
                {adminPortalTranslation?.button_accept || "Accept"}
              </Button>
            </DialogActions>
          </>
      )
    }
  }

  return {
    accept,
    loadContent,
    Pledge
  }

}

export default usePledge;