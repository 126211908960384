import {Controller} from "react-hook-form";
import {Grid, InputLabel, TextField} from "@mui/material";

const formInputStyles = {
  bgcolor: "#F1F3F4",
  borderRadius: 2,
  "& .MuiFormHelperText-root": {
    color: "red",
    margin: 0,
    padding: 1,
    backgroundColor: "#fff",
  },
};

const FormField = ({
  gridSize,
  label,
  name,
  defaultValue,
  rules,
  control,
  disabled,
  required = true,
  onChange,
  value,
}) => {
  return (
    <Grid item xs={gridSize} marginBottom="16px">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            id={name}
            disabled={disabled}
            {...field}
            fullWidth
            required={required}
            sx={formInputStyles}
            error={!!error}
            helperText={error ? error.message : null}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            value={value !== undefined ? value : field.value}
          />
        )}
      />
    </Grid>
  );
};

export default FormField;
