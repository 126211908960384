import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Title from "../../components/title";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import classes from "./Products.module.css";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContexts";

import { useAdminPortalTranslation } from "../../api/translationApi";
import { useProducts } from "../../api/productApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductList = ({ productList }) => {
  const { jwtToken } = useContext(UserContext);
  const location = useLocation();
  const isProductListPage = location.pathname.includes("products");

  const { userGroupPrefix } = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  const headers = [
    adminPortalTranslation.product_header_id,
    adminPortalTranslation.product_header_name,
    adminPortalTranslation.product_header_status,
    adminPortalTranslation.product_header_price,
    adminPortalTranslation.product_header_created,
    ...(isProductListPage
      ? [adminPortalTranslation.product_header_action]
      : []),
  ];
  if (typeof productList === "string") productList = JSON.parse(productList);

  const { products, productsAreLoading, productsError } = useProducts(
    jwtToken,
    productList
  );

  // const [products, setProducts] = useState(() => {
  //   if (productList !== undefined) return productList;
  //   else return data;
  // });

  const productsToDisplay = !isProductListPage ? productList : products;

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  if (productsError) {
    return <div>Error with fetching products...</div>;
  }

  function adminProductList() {
    return (
      <>
        {isProductListPage && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="16px"
          >
            <Box>
              <Title>{adminPortalTranslation.product_section_header}</Title>
            </Box>
            <Box>
              <Button
                to="/products/add"
                component={Link}
                variant="contained"
                size="large"
                className={classes.button}
                sx={{ borderRadius: 0 }}
              >
                {adminPortalTranslation.button_add || "Add"}
              </Button>
            </Box>
          </Box>
        )}
        <Box>
          {productsAreLoading ? (
            <>
              <Table size="large">
                <TableHead>
                  <StyledTableRow>
                    {headers.map((header) => (
                      <StyledTableCell key={header}>{header}</StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
              </Table>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
                sx={{ my: 10 }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {headers.map((header) => (
                      <StyledTableCell key={header}>{header}</StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {!productsToDisplay ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={6}>
                        No products found. Please add a producut.
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    productsToDisplay.map((product) => (
                      <StyledTableRow key={product.id}>
                        <StyledTableCell>
                          {product.id ? product.id : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {product.name ? product.name : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {product.status === "Active"
                            ? adminPortalTranslation["subscription_active"]
                            : adminPortalTranslation["subscription_inactive"]}
                        </StyledTableCell>
                        <StyledTableCell>
                          {product.price
                            ? `$${product?.price?.toFixed(2)}`
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {product.createdAt
                            ? new Date(product.createdAt).toLocaleDateString()
                            : "N/A"}
                        </StyledTableCell>
                        {isProductListPage && (
                          <StyledTableCell>
                            <Tooltip title="Edit">
                              <Link
                                className={classes.icon}
                                to={`${product.id}`}
                                state={{ selectedItem: product }}
                                style={{ padding: "4px" }}
                              >
                                <EditIcon />
                              </Link>
                            </Tooltip>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </>
    );
  }

  function simpleProductList() {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="16px"
        >
          <Box>
            <Title>{adminPortalTranslation.product_section_header}</Title>
          </Box>
        </Box>
        <Box>
          {productsAreLoading ? (
            <>
              <Table size="large">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell key={0}>ID</StyledTableCell>
                    <StyledTableCell key={1}>Name</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
              </Table>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
                sx={{ my: 10 }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell key={0}>ID</StyledTableCell>
                    <StyledTableCell key={1}>Name</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {!productsToDisplay ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={6}>
                        No products found. Please add a producut.
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    Array.isArray(productsToDisplay) &&
                    productsToDisplay.map((product) => (
                      <StyledTableRow key={product?.id}>
                        <StyledTableCell>
                          {product?.id ? product.id : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {product?.productName ? product.productName : "N/A"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </>
    );
  }

  return <>{isAdmin ? adminProductList() : simpleProductList()}</>;
};

export default ProductList;
