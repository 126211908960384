import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../contexts/UserContexts";
import ListHeader from "../../components/list-header";
import DisplayList from "../../components/display-list/DisplayList";

// import translations
import {useAdminPortalTranslation} from "../../api/translationApi";

const businessColumns = [
  { dataField: (item) => item.businessName },
  {
    dataField: (item) =>
      item.primaryContact?.firstName + " " + item.primaryContact?.lastName,
  },
  { dataField: (item) => item.status },
  { dataField: (item) => item.primaryContact?.phoneNumber },
  { dataField: (item) => item.primaryContact?.email },
];

const BusinessList = () => {
  const [businesses, setBusinesses] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { jwtToken, isLoading: isUserLoading } = useContext(UserContext);
  const url = process.env["REACT_APP_PORTAL_API_URL"];

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  const headers = [
    adminPortalTranslation?.business_header_name,
    adminPortalTranslation?.business_header_primary,
    adminPortalTranslation?.business_header_status,
    adminPortalTranslation?.business_header_phone,
    adminPortalTranslation?.business_header_email,
    adminPortalTranslation?.product_header_action,
  ];

  const fetchBusinesses = async (setLoading = true) => {
    if (setLoading) {
      setIsLoading(true);
    }
    try {
      const response = await fetch(
        `${url}/businesses${nextToken ? `?nextToken=${nextToken}` : ""}`,
        {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      );
      if (response.ok) {
        const businessList = await response.json();
        if (businessList) {
          setBusinesses([...businesses, ...businessList.data]);
          setNextToken(businessList.nextToken);
          setError(null);
        }
      } else {
        setBusinesses([]);
      }
    } catch (err) {
      setError(err.message);
      console.log(error);
      setBusinesses([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isUserLoading) {
      fetchBusinesses();
    }
  }, [isUserLoading, fetch]);

  const hasMore = () => {
    return !!nextToken;
  };

  const handleLoadMoreClick = async (e) => {
    e.preventDefault();
    await fetchBusinesses(false);
  };

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      <ListHeader
        pageTitle={adminPortalTranslation.business_section_header}
        buttonLink={`add`}
      />
      <DisplayList
        type="business"
        headers={headers}
        listData={businesses}
        columns={businessColumns}
        isLoading={isLoading}
        error={error}
        hasMore={hasMore}
        handleLoadMoreClick={handleLoadMoreClick}
        translation={adminPortalTranslation}
      />
    </>
  );
};

export default BusinessList;
