import { useContext } from "react";
import useSWR from "swr";
import UserContext from "../contexts/UserContexts";

const baseURL = process.env["REACT_APP_PORTAL_API_URL"];

const productsFetcher = async ([url, jwtToken]) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }

  const productList = await response.json();
  const filteredData = productList.data.filter((item) => item !== null);

  return filteredData;
};

export const useProducts = (jwtToken, productList) => {
  const { userGroupPrefix } = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";

  const { data, error, isLoading, mutate } = useSWR(
    isAdmin ? [baseURL + "/products", jwtToken] : null,
    productsFetcher
  );

  let productData;
  if (!!data) productData = data;
  else if (!!productList) productData = productList;
  else productData = [];

  return {
    products: productData,
    productsAreLoading: isLoading,
    productsError: error,
    mutate,
  };
};
