import React, { useContext, useEffect, useState } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LanguageContext from "../../contexts/LanguageContext";
import useTermsAndConditions from "./useTermsAndConditions";
import usePledge from "./usePledge";
import useAutoScanFirstTime from "./useAutoScanFirstTime";
import AcceptanceVideo from "./AcceptanceVideo";
import { signOut } from "aws-amplify/auth";
import { PATH_CONSTANTS } from "../../constants/pathConstants";
import { useNavigate } from "react-router-dom";

const CustomStepIcon = React.memo(({ active, completed, icon }) =>
  completed ? (
    <CheckCircleIcon sx={{ color: "orange" }} />
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: active ? "primary.main" : "grey.500",
        color: "white",
      }}
    >
      {icon}
    </Box>
  )
);

function AcceptanceStepper({ jwtToken, defaultStep = 0, onClose }) {
  const navigate = useNavigate();
  const [step, setStep] = useState(defaultStep);

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const decline = async () => {
    try {
      const language = localStorage.getItem("language");
      await signOut();
      localStorage.clear();

      if (language) {
        localStorage.setItem("language", language);
      }
      navigate(PATH_CONSTANTS.LOGIN);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const { AutoScanFirstTime, showAutoScan } = useAutoScanFirstTime(
    jwtToken,
    () => {
      if (onClose) {
        onClose();
      }
    }
  );

  const { loadContent, Pledge } = usePledge(
    jwtToken,
    () => {
      showAutoScan();
      setStep(2);
    },
    decline
  );

  const { loadTerms, TermsAndConditions } = useTermsAndConditions(
    jwtToken,
    (response) => {
      if (response?.data?.acceptPrivacyPolicy) {
        setStep(1);
      } else {
        loadContent(language).then(() => setStep(1));
      }
    },
    decline
  );

  useEffect(() => {
    loadTerms(language);
  }, [language, loadTerms]);

  const steps =
    language === "en_US"
      ? ["Terms and Conditions", "Pledge", "Video", "AutoScan"]
      : [
          "Conditions d'utilisation",
          "Promesse",
          "vidéo",
          "Analyse automatique",
        ];

  function getContent() {
    switch (step) {
      case 0:
        return <TermsAndConditions />;
      case 1:
        loadContent();
        return <Pledge></Pledge>;
      case 2:
        return <AcceptanceVideo onSkip={() => setStep(3)} />;
      case 3:
        return <AutoScanFirstTime />;
      default:
        return <TermsAndConditions />;
    }
  }

  return (
    <Box padding={4} sx={{ width: "100%" }}>
      <Stepper activeStep={step}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon {...props} icon={index + 1} />
              )}
            >
              <span style={{ fontSize: "1rem" }}>{label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {getContent()}
    </Box>
  );
}

export default AcceptanceStepper;
