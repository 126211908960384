import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DisplayList from "../../components/display-list/DisplayList";
import UserContext from "../../contexts/UserContexts";
import ListHeader from "../../components/list-header";
import {PATH_CONSTANTS} from "../../constants/pathConstants";
import {fetchMembersList} from "../../utils/api";
import {useAdminPortalTranslation} from "../../api/translationApi";

// const headers = [
//   "First Name",
//   "Last Name",
//   "Member Type",
//   "Status",
//   "Phone #",
//   "Email",
//   "Action",
// ];

const adminColumns = [
  { dataField: (item) => item.firstName },
  { dataField: (item) => item.lastName },
  { dataField: (item) => item.status },
  { dataField: (item) => item.memberType },
  { dataField: (item) => item.phoneNumber },
  { dataField: (item) => item.email },
];

const AdministratorList = ({ translation }) => {
  const [customers, setCustomers] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { jwtToken, isLoading: isUserLoading } = useContext(UserContext);
  const { businessId } = useParams();
  const type = "BA";
  const adminPathConstants = { LIST: PATH_CONSTANTS.BUSINESS_LIST };

  const { adminPortalTranslation } = useAdminPortalTranslation();

  const headers = [
    adminPortalTranslation?.business_first_name,
    adminPortalTranslation?.business_last_name,
    adminPortalTranslation?.business_header_status,
    adminPortalTranslation?.business_header_primary,
    adminPortalTranslation?.business_header_phone,
    adminPortalTranslation?.business_header_email,
    adminPortalTranslation?.product_header_action,
  ];

  const fetchAdministrators = async (setLoading = true) => {
    if (setLoading) {
      setIsLoading(true);
    }

    try {
      const administratorList = await fetchMembersList(
        businessId,
        jwtToken,
        nextToken,
        type
      );

      if (administratorList) {
        setCustomers([...customers, ...administratorList.data]);
        setNextToken(administratorList.nextToken);
        setError(null);
      }
    } catch (err) {
      setError(err.message);
      console.log(error);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isUserLoading) {
      fetchAdministrators();
    }
  }, [isUserLoading]);

  const hasMore = () => {
    return !!nextToken;
  };

  const handleLoadMoreClick = async (e) => {
    e.preventDefault();
    await fetchAdministrators(false);
  };

  return (
    <>
      <ListHeader
        pageTitle={translation.administrator_section_header}
        buttonLink={`adminstrators/add`}
      />
      <DisplayList
        type="Administrator"
        headers={headers}
        listData={customers}
        columns={adminColumns}
        pathConstants={adminPathConstants}
        isLoading={isLoading}
        error={error}
        hasMore={hasMore}
        handleLoadMoreClick={handleLoadMoreClick}
        editRoute="adminstrators"
        translation={adminPortalTranslation}
      />
    </>
  );
};

export default AdministratorList;
