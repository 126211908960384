import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useCallback, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import useSnackbar from "../snack-bar/useSnackBar";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const UploadImage = ({ setPreview, translation }) => {
  const [loading, setLoading] = useState(false);
  const jwtToken = localStorage.getItem("idToken");
  const cssClassName = "dropzone-avatar";
  const location = useLocation();
  const profilePage = location.pathname.includes("profile");
  const { businessId } = useParams();
  const uploadType = profilePage ? "avatar" : "logo";
  const { show, CustomSnackBar, setMessage } = useSnackbar();
  const { t } = useInternalTranslation();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = async (fileData) => {
    let statusMessage;
    if (profilePage) {
      statusMessage = "Upload of Avatar in progress";
    } else {
      statusMessage = "Upload of Logo in progress";
    }

    show(t(statusMessage), "info");
    const url = profilePage
      ? `${process.env.REACT_APP_API_URL}/members/avatar`
      : `${process.env.REACT_APP_API_URL}/businesses/${businessId}/logos`;
    try {
      const imageData = await getBase64(fileData);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ [uploadType]: imageData }),
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        show(`${t(`Failed to submit`)} ${uploadType}: ${response.statusText}`);
      } else {
        const data = await response.json();
        // setTimeout(() => {
        // show("Upload Complete, refreshing...");
        // window.location.reload();
        // }, 2000)
        if (!!setPreview) setPreview(data.data.logoURL);
        show(translation["upload_complete"]);
      }
    } catch (err) {
      show(translation["upload_failed"], "error");
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const maxImageHeight = 800,
        maxImageWidth = 800;
      if (!acceptedFiles || acceptedFiles.length < 1) {
        setMessage(translation["image_spec"]);
      }
      const fr = new FileReader();

      fr.onload = function () {
        const img = new Image();

        img.onerror = async function () {
          show(translation["image_spec"], "error");
        };

        img.onload = async function () {
          if (img.width > maxImageWidth || img.height > maxImageHeight) {
            show(translation["image_spec"], "error");
          } else {
            await uploadImage(acceptedFiles[0]);
          }
        };

        if (typeof fr.result === "string") {
          img.src = fr.result;
        } else {
          show(translation["failed_upload_img"], "error");
        }
      };
      fr.readAsDataURL(acceptedFiles[0]);
    } catch (err) {
      show(translation["failed_upload"], "error");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <>
      <Box
        style={{
          border: "1px dashed #ccc",
          backgroundColor: "#F1F3F4",
          padding: "32px 16px",
        }}
      >
        <CustomSnackBar />
        <Box {...getRootProps()} className={cssClassName}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <Box style={{ textAlign: "center" }}>
              <CloudUploadIcon
                style={{
                  fontSize: "4rem",
                  display: "block",
                  margin: "0px auto",
                }}
              />
              <Typography variant="body1" fontWeight={400}>
                {profilePage
                  ? translation.avatar_upload
                  : `Drag and drop file here or Choose file`}
              </Typography>
              <Typography variant="h6" fontWeight={400}>
                {translation["upload_file"]}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {loading && <CircularProgress />}
    </>
  );
};

export default UploadImage;
