import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Title from "../title/Title";
import { Link, useLocation } from "react-router-dom";
import classes from "./ProductForm.module.css";
import UserContext from "../../contexts/UserContexts";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const AddProduct = ({ method, translation }) => {
  const { handleSubmit, control, reset } = useForm();
  const [open, setOpen] = useState(false);
  const { jwtToken } = useContext(UserContext);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const { selectedItem } = location.state || {};
  const addProduct = method === "POST";
  const { t } = useInternalTranslation();

  function validDuration(value) {
    if (value > 99 || value < 1) {
      return false;
    } else {
      return true;
    }
  }

  const onSubmit = async (data) => {
    let url = `${process.env["REACT_APP_PORTAL_API_URL"]}/products`;

    if (!addProduct) {
      url += "/" + selectedItem?.id;
    }

    const productData = {
      name: String(data.name),
      description: String(data.description),
      durationType: String(data.durationType),
      durationValue: Number(data.durationValue),
      price: Number(data.price),
    };

    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      },
      body: JSON.stringify(productData),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        console.log(`Error: ${response.status} ${response.statusText}`);
        throw new Error("Failed to submit form");
      } else {
        reset();
      }
      setOpen(true);
    } catch (err) {
      setErrorMessage("Error: " + err.message);
    }
  };

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 250);
  }

  const action = (
    <>
      &nbsp; &nbsp; &nbsp;
      <Button
        color="secondary"
        size="small"
        sx={{
          color: "white",
        }}
        component={Link}
        to={`/products`}
      >
        <u>Back to Products</u>
      </Button>
    </>
  );

  return (
    <>
      <Snackbar
        open={open || errorMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={!errorMessage ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {!!errorMessage && errorMessage}
          {!errorMessage &&
            !addProduct &&
            translation["success_update_product"]}
          {!errorMessage && addProduct && translation["success_create_product"]}
          {!errorMessage && action}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <Box>
          <Title>{addProduct ? t("Add Product") : t("Edit Product")}</Title>
        </Box>
      </Box>
      <Box
        className={classes.form}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name">{translation.product_name}</InputLabel>
            <Controller
              name="name"
              disabled={selectedItem?.isRetail}
              control={control}
              defaultValue={
                selectedItem && selectedItem?.name ? selectedItem?.name : ""
              }
              rules={{ required: t("Product name is required") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="name"
                  sx={{
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      margin: 0,
                      padding: 1,
                      backgroundColor: "#fff",
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          {!addProduct && (
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="productId">
                {translation.product_id}
              </InputLabel>
              <TextField
                disabled
                defaultValue={selectedItem?.id ?? ""}
                fullWidth
                id="productId"
                sx={{
                  bgcolor: "#F1F3F4",
                  borderRadius: 2,
                  "& .MuiFormHelperText-root": {
                    color: "red",
                    margin: 0,
                    padding: 1,
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel htmlFor="description">
              {translation.product_description}
            </InputLabel>
            <Controller
              name="description"
              disabled={selectedItem?.isRetail}
              control={control}
              defaultValue={selectedItem ? selectedItem?.description : ""}
              rules={{ required: t("Product description is required") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="description"
                  multiline
                  maxRows={4}
                  sx={{
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      margin: 0,
                      padding: 1,
                      backgroundColor: "#fff",
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="durationValue">
              {translation.product_duration}
            </InputLabel>
            <Controller
              name="durationValue"
              disabled={selectedItem?.isRetail}
              control={control}
              defaultValue={selectedItem ? selectedItem?.durationValue : ""}
              rules={{
                required: t("Product duration is required"),
                validate: (value) => {
                  return (
                    validDuration(value) ||
                    t("The duration must be between 1-99")
                  );
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  fullWidth
                  id="durationValue"
                  sx={{
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      margin: 0,
                      padding: 1,
                      backgroundColor: "#fff",
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="durationType">
              {translation.product_duration_type}
            </InputLabel>
            <Controller
              name="durationType"
              disabled={selectedItem?.isRetail}
              control={control}
              defaultValue={selectedItem ? selectedItem?.durationType : ""}
              rules={{ required: t("Product duration type is required") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="durationType"
                  select
                  fullWidth
                  sx={{
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      margin: 0,
                      padding: 1,
                      backgroundColor: "#fff",
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                >
                  <MenuItem value="Days">{t("Day(s)")}</MenuItem>
                  <MenuItem value="Months">{t("Month(s)")}</MenuItem>
                  <MenuItem value="Years">{t("Year(s)")}</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          {!addProduct && (
            <Grid item xs={6}>
              <InputLabel htmlFor="price">Number of licenses</InputLabel>
              <Controller
                name="licenseCount"
                control={control}
                defaultValue={selectedItem ? selectedItem?.numLicenses ?? 1 : 1}
                rules={{
                  validate: (value) => {
                    // You can still validate the value if needed, for example, the range between 1 and 99
                    return (
                      !value ||
                      validDuration(value) ||
                      t("The amount of licenses must be between 1-99")
                    );
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled
                    {...field}
                    fullWidth
                    type="number"
                    id="licenseCount"
                    sx={{
                      bgcolor: "#F1F3F4",
                      borderRadius: 2,
                      "& .MuiFormHelperText-root": {
                        color: "red",
                        margin: 0,
                        padding: 1,
                        backgroundColor: "#fff",
                      },
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <InputLabel htmlFor="price">{translation.product_price}</InputLabel>
            <Controller
              name="price"
              disabled={selectedItem?.isRetail}
              control={control}
              defaultValue={selectedItem ? selectedItem?.price : ""}
              rules={{ required: t("Product price is required") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  id="price"
                  sx={{
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      margin: 0,
                      padding: 1,
                      backgroundColor: "#fff",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={6}>
            <Button
              className={classes.button}
              component={Link}
              type="button"
              to="/products"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 0 }}
            >
              {translation.button_cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              disabled={selectedItem?.isRetail}
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 0 }}
              type="submit"
            >
              {translation.button_save}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddProduct;
