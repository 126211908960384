import {Authenticator, Image as ReactImage, useTheme, View,} from "@aws-amplify/ui-react";
import ScamneticLogo from "../../assets/KnowScam_black-small.png";
import useCobrand from "../co-branding/useCobrand";
import LanguageContext from "../../contexts/LanguageContext";
import {useContext} from "react";

function showLogo(customLogo) {
  if (customLogo) {
    return <ReactImage alt="Scamnetic logo" src={customLogo} width="220px" />;
  } else {
    return <ReactImage alt="Scamnetic logo" src={ScamneticLogo} width="60px" />;
  }
}

const Components = {
  Header() {
    const { tokens } = useTheme();
    const { customLogo, logoLoaded } = useCobrand();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {logoLoaded && showLogo(customLogo)}
      </View>
    );
  },
  SignUp: {
    FormFields() {
      const { language } = useContext(LanguageContext);
      return (
        <>
          <Authenticator.SignUp.FormFields />
          <input
            type="hidden"
            name="custom:language_pref"
            value={language ? language : "en_US"}
          />
        </>
      );
    },
  },
};

export default Components;
