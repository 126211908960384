import {useLocation, useParams} from "react-router-dom";
import CustomerForm from "../../components/customer-form";

function EditCustomer() {
  const { businessId } = useParams();
  let { state } = useLocation();

  return (
    <CustomerForm
      method="PUT"
      event={businessId}
      selectedCustomer={state.selectedItem}
    />
  );
}

export default EditCustomer;
