import useSWR from "swr";

const baseUrl = process.env.REACT_APP_PORTAL_API_URL;

const SESSION_KEY = "_memberDetails";

const memberDetailsFetcher = async ([url, jwtToken]) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch resource");
  }

  const data = await response.json();

  return data;
};

export const useMemberDetails = (jwtToken) => {
  const { data, error, isLoading, mutate } = useSWR(
    [baseUrl + "/members/details", jwtToken],
    memberDetailsFetcher
  );

  return {
    memberDetails: data ? data : {},
    memberDetailsAreLoading: isLoading,
    memberDetailsError: error,
    mutate,
  };
};

const getFromSession = async (jwtToken) => {
  let cachedData = sessionStorage.getItem(SESSION_KEY);

  if (cachedData) {
    return JSON.parse(cachedData);
  } else {
    // If no cached data, fetch from API
    cachedData = await fetchMemberDetails(jwtToken);
    if (cachedData) {
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(cachedData));
    } else {
      console.log("Failed to fetch member details", { cachedData });
    }
  }

  return cachedData;
};

const fetchMemberDetails = async (jwtToken) => {
  try {
    const url = `${baseUrl}/members/details`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch resource");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting member details: ", error);
  }
};

export const getMemberDetails = async (jwtToken, resync = false) => {
  if (resync) {
    sessionStorage.removeItem(SESSION_KEY);
  }
  return await getFromSession(jwtToken);
  // try {
  //   const url = `${baseUrl}/members/details`;
  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + jwtToken,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //
  //   if (!response.ok) {
  //     throw new Error("Failed to fetch resource");
  //   }
  //
  //   const data = await response.json();
  //
  //   return data;
  // } catch (error) {
  //   console.error("Error getting member details: ", error);
  // }
};
