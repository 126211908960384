import { useContext, useState } from "react";

// import MUI
import { Box, TextField } from "@mui/material";

// import components
import ListHeader from "../../components/list-header";
import AddressTable from "./components/AddressTable";
import EditModal from "./components/EditModal";

// import API
import {
  deleteDomain,
  updateDomain,
  useWhitelist,
} from "../../api/whitelistApi";

// import custom hooks
import useSnackBar from "../../components/snack-bar/useSnackBar";

// import contexts
import UserContext from "../../contexts/UserContexts";
import LanguageContext from "../../contexts/LanguageContext";

const headers = ["Domain", "Hosting IP", "Action"];
const domainColumns = [
  { dataField: (item) => item.domain },
  {
    dataField: (item) => item.ip,
  },
];

const WhiteList = () => {
  const { jwtToken } = useContext(UserContext);
  const { whitelist, isLoading, error, mutate } = useWhitelist(jwtToken);

  const [filterText, setFilterText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingDomain, setEditingDomain] = useState(null);

  const { CustomSnackBar, show } = useSnackBar();

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const failedToDeleteDomain =
    language === "en_US"
      ? "Failed to delete domain"
      : "Échec de la suppression du domaine";
  const invalidDomainName =
    language === "en_US" ? "Invalid domain name" : "Nom de domaine invalide";
  const invalidIpAddress =
    language === "en_US" ? "Invalid IP address" : "Adresse IP invalide";
  const domainUpdatedSuccessfully =
    language === "en_US"
      ? "Domain updated successfully"
      : "Domaine mis à jour avec succès";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong. Please try again later.</div>;
  }

  const handleDelete = async (domainName) => {
    try {
      await deleteDomain(jwtToken, domainName);
      mutate();
    } catch (err) {
      show(failedToDeleteDomain, "error");
      console.error("Failed to delete domain:", err);
    }
  };

  const validateDomain = (domain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    return domainRegex.test(domain);
  };

  const validateIP = (ip) => {
    const ipv4Regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)$/;
    return ipv4Regex.test(ip) || ipv6Regex.test(ip);
  };

  const handleUpdate = async (domainName, updatedDomain) => {
    if (!validateDomain(updatedDomain.domain)) {
      show(invalidDomainName, "error");
      return;
    }

    if (updatedDomain.ip && !validateIP(updatedDomain.ip)) {
      show(invalidIpAddress, "error");
      return;
    }

    try {
      await updateDomain(jwtToken, domainName, updatedDomain);
      mutate();
      handleCloseModal();
      show(domainUpdatedSuccessfully, "success");
    } catch (err) {
      console.error("Failed to update domain:", err);
      show("Failed to add new domain", "error");
    }
  };

  const handleStartEditing = (domain) => {
    setEditingDomain(domain);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setEditingDomain(null);
  };

  return (
    <>
      <CustomSnackBar />
      <EditModal
        open={editModalOpen}
        onClose={handleCloseModal}
        editingDomain={editingDomain}
        onUpdate={handleUpdate}
      />
      <ListHeader pageTitle="Whitelist IP Addresses" buttonLink={`add`} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <TextField
          id="outlined-basic"
          label="Search by domain name"
          variant="outlined"
          fullWidth
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </Box>

      <AddressTable
        type="business"
        headers={headers}
        whitelist={whitelist}
        columns={domainColumns}
        isLoading={isLoading}
        error={error}
        filterText={filterText}
        onDelete={handleDelete}
        onEdit={handleStartEditing}
      />
    </>
  );
};

export default WhiteList;
