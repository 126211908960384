import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import useFileUpload from "../../hooks/useFileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UserContext from "../../contexts/UserContexts";
import { useAdminPortalTranslation } from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";

const BusinessSettings = ({ businessData }) => {
  const baseURL = process.env["REACT_APP_PORTAL_API_URL"];
  const { businessId } = useParams();
  const { jwtToken } = useContext(UserContext);
  const uploadUrl = `${baseURL}/businesses/${businessId}/batchenrollment`;
  const maxSize = 10 * 1024 * 1024;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const memberEnrolled = businessData?.data?.isAPIEnrolled;
  const primaryContactEmail = businessData?.data?.primaryContact?.email;
  const clientId = businessData?.data?.id;
  const [secret, setSecret] = useState("");
  const [showEnrollFields, setShowEnrollFields] = useState(memberEnrolled);

  useEffect(() => {
    setShowEnrollFields(memberEnrolled);
  }, [memberEnrolled]);

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const networkError =
    language === "en_US"
      ? "Network response was not ok"
      : "La réponse du réseau n'était pas correcte";
  const errorEnrolling =
    language === "en_US"
      ? "Error enrolling business"
      : "Erreur lors de l'inscription de l'entreprise";
  const secretCopied =
    language === "en_US"
      ? "Secret copied to clipboard"
      : "Secret copié dans le presse-papiers";
  const failedCopySecret =
    language === "en_US"
      ? "Failed to copy secret"
      : "Impossible de copier le secret";

  const showMessage = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    handleConfirm,
    handleCancel,
    selectedFile,
  } = useFileUpload(uploadUrl, "text/csv", jwtToken, maxSize, showMessage);

  const handleEnrollClick = async () => {
    try {
      const response = await fetch(
        `${baseURL}/businesses/${businessId}/enroll`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: primaryContactEmail,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(networkError);
      }

      const data = await response.json();
      setSecret(data.secret);
      setShowEnrollFields(true); // Show the input fields
    } catch (error) {
      showMessage(errorEnrolling, "error");
    }
  };

  const handleCopySecret = () => {
    navigator.clipboard
      .writeText(secret)
      .then(() => {
        showMessage(secretCopied);
      })
      .catch((error) => {
        showMessage(failedCopySecret, "error");
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error...</div>;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }} gutterBottom>
              {adminPortalTranslation["member_management"]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" fontWeight={400}>
              {adminPortalTranslation["enable_member_enrolment"]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} justifyContent="flex-end">
          {!showEnrollFields && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleEnrollClick}
              >
                {adminPortalTranslation["enable"]}
              </Button>
            </Box>
          )}
        </Grid>
        {showEnrollFields && (
          <>
            <Grid item xs={6} md={6}>
              <Box>
                <InputLabel shrink={false} htmlFor={"clientId"}>
                  <Typography>{adminPortalTranslation["client_id"]}</Typography>
                </InputLabel>
                <TextField
                  sx={{ bgcolor: "#F1F3F4" }}
                  id="clientId"
                  value={clientId ? clientId : ""}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                <InputLabel shrink={false} htmlFor={"secret"}>
                  <Typography>{adminPortalTranslation["secret"]}</Typography>
                </InputLabel>
                <TextField
                  sx={{ bgcolor: "#F1F3F4" }}
                  id="secret"
                  value={secret}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleCopySecret}>
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="subtitle2">
                  {adminPortalTranslation["password_show_once"]}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="h7" fontWeight={600}>
            {adminPortalTranslation["upload_csv"]}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
            p={6}
            border="1px dashed #ccc"
            backgroundColor="#F1F3F4"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon
              style={{
                fontSize: "4rem",
                display: "block",
                margin: "0px auto",
              }}
            />
            <Typography variant="body1" fontWeight={400}>
              {adminPortalTranslation["drag_and_drop_csv"]}
            </Typography>
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <Typography variant="caption" fontWeight={400} fontSize={10}>
                {adminPortalTranslation["supported_formats"]}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="caption" fontWeight={400} fontSize={10}>
                {adminPortalTranslation["max_size_10mb"]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}></Grid>
      </Grid>

      <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
        <DialogTitle>{adminPortalTranslation["update_members"]}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {adminPortalTranslation["process_file_question"]} "
            {selectedFile?.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {adminPortalTranslation["button_cancel"]}
          </Button>
          <Button onClick={handleConfirm} color="secondary" variant="contained">
            {adminPortalTranslation["yes"]}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BusinessSettings;
