import {Button, Dialog} from "@mui/material";
import {useContext, useState} from "react";
import classes from "../../customer-details/CustomerDetails.module.css";
import UserContext from "../../../../../contexts/UserContexts";
import {useNavigate} from "react-router-dom";
import {signOut} from "aws-amplify/auth";
import {PATH_CONSTANTS} from "../../../../../constants/pathConstants";

const DeleteAccount = ({ username, translation }) => {
  const { jwtToken } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const signOutHandler = async () => {
    try {
      await signOut();
      localStorage.clear();
      navigate(PATH_CONSTANTS.LOGIN);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  async function deleteAccount() {
    const url = `${process.env["REACT_APP_API_URL"]}/members/profile/admin/${username}`;
    const response = await fetch(
      url,

      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + jwtToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.status === 200 ||
      response.status === 202 ||
      response.status === 204
    ) {
      signOutHandler();
    } else {
      throw new Error("Failed to delete account");
    }
  }

  return (
    <>
      <Dialog
        open={open}
        sx={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            gap: "15px",

            textAlign: "center",
            marginTop: "20px",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <h1
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {translation.delete_account}
          </h1>
          <h2
            style={{
              fontWeight: 500,
              padding: 0,
              margin: 0,
              fontSize: 18,
            }}
            dangerouslySetInnerHTML={{
              __html: translation["delete_account_blurb"],
            }}
          ></h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "50px",

              marginBottom: "25px",
            }}
          >
            <Button
              variant="contained"
              size="large"
              className={classes.greyButton}
              sx={{
                fontWeight: 800,
              }}
              onClick={() => setOpen(false)}
            >
              {translation.button_cancel}
            </Button>
            <Button
              variant="contained"
              size="large"
              className={classes.saveButton}
              sx={{
                fontWeight: 800,
              }}
              onClick={() => deleteAccount()}
            >
              {translation.delete_account}
            </Button>
          </div>
        </div>
      </Dialog>
      <Button
        onClick={() => setOpen(true)}
        className={classes.greyButton}
        variant="contained"
        size="small"
        sx={{ borderRadius: 1, minWidth: "50%" }}
      >
        {translation.delete_my_account}
      </Button>
    </>
  );
};

export default DeleteAccount;
