import {Alert, Snackbar} from "@mui/material";
import React, {useState} from "react";

const useSnackBar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [duration, setDuration] = useState(6000);

  const onClosing = (callback) => {
    setOpen(false);
    // if (callback) {
    //     callback();
    // }
  };

  const show = (message, type = "success", duration = 6000) => {
    setMessage(message);
    setType(type);
    setDuration(duration);
    setOpen(true);
  };

  const getType = () => {
    switch (type) {
      case "success":
        return "success";
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "info":
        return "info";
      default:
        return "success";
    }
  };

  const CustomSnackBar = () => {
    return (
      <Snackbar open={open} autoHideDuration={duration} onClose={onClosing}>
        <Alert
          onClose={onClosing}
          severity={getType()}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  };

  return {
    show,
    onClosing,
    open,
    CustomSnackBar,
  };
};

export default useSnackBar;
