// import react
import {useContext, useEffect, useState} from "react";

// import material ui components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import API
import {fetchFaq} from "../../../utils/api";

// import context
import LanguageContext from "../../../contexts/LanguageContext";

const FAQ = () => {
  const [faq, setfaq] = useState(null);
  const [title] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const { language } = useContext(LanguageContext);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFaq(language);
        setfaq(data);
      } catch (err) {}
    };

    fetchData();
  }, [language]);

  if (!faq) {
    return "Loading...";
  }
  return (
    <div>
      <Typography fontSize={29} fontWeight={700} marginBottom={2} marginTop={5}>
        {title}
      </Typography>
      {[...faq.items].map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleExpansion(`panel${index}`)}
          sx={{
            "& .MuiAccordion-region": {
              height: expanded === `panel${index}` ? "auto" : 0,
            },
            "& .MuiAccordionDetails-root": {
              display: expanded === `panel${index}` ? "block" : "none",
            },
            marginBottom: "16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography sx={{ fontWeight: 800 }}>
              {item.fields.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.fields.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
