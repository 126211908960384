import {Link, useNavigate, useRouteError} from "react-router-dom";
import Navigation from "../../components/navigation/Navigation";
import {Box} from "@mui/material";
import Footer from "../../components/footer/Footer";
import styles from "./ErrorPage.module.css";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  let title = "An Error occured!";
  let message = "Something went wrong";

  if (error.status === 500) {
    message = error.data.message;
  }
  if (error.status === 404) {
    title = "Page not found!";
    message = "The page you are looking for does not exist";
  }

  return (
    <>
      <Navigation />
      <Box className={styles.container}>
        <Box style={{ textAlign: "center" }}>
          <h1>{title}</h1>
          {message}
          <Box>
            <Link onClick={() => navigate.back}>Go Back</Link>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ErrorPage;
