import { useForm } from "react-hook-form";
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// import API
import { useProducts } from "../../api/productApi";

const formInputStyles = {
  bgcolor: "#F1F3F4",
  borderRadius: 2,
  "& .MuiFormHelperText-root": {
    color: "red",
    margin: 0,
    padding: 1,
    backgroundColor: "#fff",
  },
};

const SubscriptionPlan = ({
  planType,
  onChange,
  translation,
  disabled = false,
}) => {
  const jwtToken = localStorage.getItem("idToken");
  const {
    register,
    formState: { errors },
  } = useForm();

  const { products, productsAreLoading, productsError } = useProducts(jwtToken);

  if (productsAreLoading) {
    return <Typography fontWeight="bold">Loading...</Typography>;
  }

  if (productsError) {
    return <Typography fontWeight="bold">{productsError}</Typography>;
  }

  console.log("currentProduct", planType?.productDetails)

  let currentProduct = [];
  if (!!planType?.productDetails) {
    currentProduct = planType?.productDetails;
    if (typeof currentProduct === "string")
      currentProduct = JSON.parse(currentProduct);
    currentProduct = currentProduct[0];
  }

  if (products.length === 0) {
    products.push(currentProduct);
    // debugger;
  }

  return (
    <>
      <Box>
        {/* <Typography variant="h4" sx={{ mb: 3 }} gutterBottom>
          Subscription Plan
        </Typography> */}
        {!products ? (
          <Typography fontWeight="bold">Test</Typography>
        ) : (
          <>
            <InputLabel htmlFor="product">
              {translation.business_product}
            </InputLabel>
            {planType && (
              <Select
                fullWidth
                id="product"
                {...register("product", { required: true })}
                defaultValue={planType ? currentProduct.id : ""}
                sx={formInputStyles}
                onChange={onChange}
                disabled={disabled}
              >
                <MenuItem value="" disabled>
                  Choose a product
                </MenuItem>
                {products.map((product, index) => (
                  <MenuItem key={index} value={product.id}>
                    {product?.name || product?.productName}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors.product && <span>This field is required</span>}
          </>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPlan;
