// import react hooks
import {useEffect, useState} from "react";

// import MUI components
import {Box, Button, Grid, Modal, TextField, Typography} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1/3",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditModal({ open, onClose, editingDomain, onUpdate }) {
  const [newDomain, setNewDomain] = useState(null);

  useEffect(() => {
    if (!editingDomain) return;
    setNewDomain(editingDomain);
  }, [editingDomain]);

  if (!editingDomain || !newDomain) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Edit Domain: {editingDomain.domain}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="domain"
              label="Domain Name"
              variant="outlined"
              fullWidth
              value={newDomain.domain}
              onChange={(e) =>
                setNewDomain({ ...newDomain, domain: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="ip"
              label="IP Address (Optional)"
              variant="outlined"
              fullWidth
              value={newDomain.ip}
              onChange={(e) =>
                setNewDomain({ ...newDomain, ip: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              onClick={onClose}
              color="tertiary"
              variant="contained"
              size="large"
              sx={{ my: 4, borderRadius: 0 }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => onUpdate(editingDomain.domain, newDomain)}
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 0 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default EditModal;
