import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import HeaderTitle from "../header-title";
import classes from "./ListHeader.module.css";
import {useAdminPortalTranslation} from "../../api/translationApi";

const ListHeader = ({ pageTitle, Business, buttonLink }) => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="16px"
    >
      <Box>
        <HeaderTitle>{pageTitle}</HeaderTitle>
      </Box>
      <Box>
        <Button
          component={Link}
          to={!!buttonLink ? buttonLink : "add"}
          variant="contained"
          size="large"
          className={classes.button}
          sx={{ borderRadius: 0 }}
        >
          {adminPortalTranslation?.button_add || "Add"}
        </Button>
      </Box>
    </Box>
  );
};
export default ListHeader;
