import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAdminPortalTranslation } from "../api/translationApi";

const useFileUpload = (
  url,
  acceptedFileTypes = "text/csv",
  jwtToken,
  maxSize = 10 * 1024 * 1024,
  showMessage
) => {
  const [uploadState, setUploadState] = useState({
    open: false,
    selectedFile: null,
  });

  const { adminPortalTranslation } = useAdminPortalTranslation();

  const ERROR_MESSAGES = {
    INVALID_TYPE:
      adminPortalTranslation["invalid_file_type"] || "Invalid file type",
    EXCEEDS_SIZE:
      `${adminPortalTranslation["exceed_max_file_size"]} (${
        maxSize / (1024 * 1024)
      } MB)` || `File exceeds maximum size (${maxSize / (1024 * 1024)} MB)`,
    INVALID_EXTENSION: (validExtensions) =>
      `Invalid file extension. Please upload files with the following extensions: ${validExtensions.join(
        ", "
      )}`,
    UPLOAD_FAILED:
      adminPortalTranslation["failed_process_file"] || "Failed to process file",
    UPLOAD_SUCCESS:
      adminPortalTranslation["success_upload_file"] ||
      "File uploaded successfully",
    UPLOAD_ERROR:
      adminPortalTranslation["error_upload_file"] || "Error uploading file",
  };

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop();
    const validExtensions = acceptedFileTypes
      .split(",")
      .map((type) => type.trim().split("/").pop());

    if (!acceptedFileTypes.includes(file.type)) {
      showMessage(ERROR_MESSAGES.INVALID_TYPE, "error");
      return false;
    }

    if (file.size > maxSize) {
      showMessage(ERROR_MESSAGES.EXCEEDS_SIZE, "error");
      return false;
    }

    if (!validExtensions.includes(fileExtension)) {
      showMessage(ERROR_MESSAGES.INVALID_EXTENSION(validExtensions), "error");
      return false;
    }

    return true;
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];

    if (!validateFile(file)) return;

    setUploadState({ open: true, selectedFile: file });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleConfirm = () => {
    const { selectedFile } = uploadState;
    if (!selectedFile) return;

    setUploadState((prevState) => ({ ...prevState, open: false }));

    const formData = new FormData();
    formData.append("file", selectedFile);

    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "Failed to process file") {
          showMessage(ERROR_MESSAGES.UPLOAD_FAILED, "error");
        } else {
          showMessage(ERROR_MESSAGES.UPLOAD_SUCCESS, "success");
        }
      })
      .catch((error) => {
        console.error(error);
        showMessage(ERROR_MESSAGES.UPLOAD_ERROR, "error");
      });
  };

  const handleCancel = () => {
    setUploadState({ open: false, selectedFile: null });
  };

  return {
    getRootProps,
    getInputProps,
    open: uploadState.open,
    handleConfirm,
    handleCancel,
    selectedFile: uploadState.selectedFile,
  };
};

export default useFileUpload;
