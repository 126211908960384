// import react and router hooks
import {useCallback, useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

// import API
// import API
import {postDomain, uploadDomainsCSV, useWhitelist} from "../../api/whitelistApi";

import Papa from "papaparse";

// import MUI
import {Box, Button, Checkbox, FormControlLabel, Grid, InputLabel, TextField, Typography,} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// import components
import HeaderTitle from "../../components/header-title/HeaderTitle";

// import contexts
import UserContext from "../../contexts/UserContexts";

// import custom hooks
import useSnackBar from "../../components/snack-bar/useSnackBar";
import {useDropzone} from "react-dropzone";

const formInputStyles = {
  bgcolor: "#F1F3F4",
  borderRadius: 2,
  "& .MuiFormHelperText-root": {
    color: "red",
    margin: 0,
    padding: 1,
    backgroundColor: "#fff",
  },
};

function WhitelistAdd() {
  const { jwtToken } = useContext(UserContext);
  const { mutate } = useWhitelist();
  const navigate = useNavigate();

  const [newDomain, setNewDomain] = useState({
    domain: "",
    ip: "",
  });

  // state for whether we are adding another domain
  const [addingAnother, setAddingAnother] = useState(false);

  // useSnackBar hook
  const { show, CustomSnackBar } = useSnackBar();

  const validateDomain = (domain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    return domainRegex.test(domain);
  };

  const validateIP = (ip) => {
    const ipv4Regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)$/;
    return ipv4Regex.test(ip) || ipv6Regex.test(ip);
  };

  const handleAddDomain = async () => {
    if (newDomain.domain === "") return;

    if (!validateDomain(newDomain.domain)) {
      show("Invalid domain name", "error");
      return;
    }

    // IP address is optional, but if provided, validate it
    if (newDomain.ip && !validateIP(newDomain.ip)) {
      show("Invalid IP address", "error");
      return;
    }

    try {
      show("Adding domain", "info");
      await postDomain(jwtToken, newDomain);

      mutate();

      setNewDomain({
        domain: "",
        ip: "",
      });

      if (!addingAnother) {
        navigate("/whitelist");
      }

      setAddingAnother(false);
      show("Domain added successfully", "success");
    } catch (err) {
      console.error("Failed to add new domain:", err);
      show("Failed to add new domain", "error");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      try {
        if (acceptedFiles[0].type !== "text/csv") {
          throw new Error("Invalid file type");
        }

        show("Parsing CSV file", "info");

        const file = acceptedFiles[0];

        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: async (result) => {
            console.log(result.data);

            if (
              !Array.isArray(result.meta.fields) ||
              result.meta.fields.length !== 2 ||
              !result.meta.fields.every(
                (field, index) => ["domain", "ip"][index] === field
              )
            ) {
              show(
                "Invalid CSV format. Headers must be 'domain' and 'ip'",
                "error"
              );
              return;
            }

            show("CSV file parsed successfully", "success");
            await uploadDomainsCSV(jwtToken, result.data);
            mutate();
            show("Domains added successfully", "success");
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
            show("Failed to parse CSV file", "error");
          },
        });
      } catch (error) {
        console.error("Failed to handle file upload:", error);
        show("Failed to handle file upload", "error");
      }
    },
    [show, jwtToken, mutate]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <>
      <HeaderTitle>Add Whitelisting</HeaderTitle>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel htmlFor="domain">Domain Name</InputLabel>
            <TextField
              id="domain"
              variant="outlined"
              fullWidth
              value={newDomain.domain}
              onChange={(e) =>
                setNewDomain({ ...newDomain, domain: e.target.value })
              }
              sx={formInputStyles}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="ip">IP Address (Optional)</InputLabel>
            <TextField
              id="ip"
              variant="outlined"
              fullWidth
              value={newDomain.ip}
              onChange={(e) =>
                setNewDomain({ ...newDomain, ip: e.target.value })
              }
              sx={formInputStyles}
            />
          </Grid>

          {/* drop zone for uploaded file */}
          <Grid item xs={6}>
            <Box
              {...getRootProps()}
              style={{
                border: "1px dashed #ccc",
                backgroundColor: "#F1F3F4",
                padding: "32px 16px",
                textAlign: "center",
                height: "200px",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography variant="body1" fontWeight={400}>
                  Drag here
                </Typography>
              ) : (
                <>
                  <CloudUploadIcon
                    style={{
                      fontSize: "4rem",
                      display: "block",
                      margin: "0px auto",
                    }}
                  />
                  <Typography variant="body1" fontWeight={400}>
                    Drag 'n' drop your domains CSV file here, or click to select
                    it
                  </Typography>
                  <Typography variant="h6" fontWeight={400}>
                    Upload file
                  </Typography>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              id="addAnother"
              control={<Checkbox />}
              label="Add another whitelisting"
              checked={addingAnother}
              onChange={(e) => setAddingAnother(e.target.checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleAddDomain}
              component={Link}
              to="/whitelist"
              color="tertiary"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 0 }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleAddDomain}
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              sx={{ my: 4, borderRadius: 0 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <CustomSnackBar />
      </Box>
    </>
  );
}

export default WhitelistAdd;
