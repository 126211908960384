import {useContext, useState} from "react";
import {updatePassword} from "aws-amplify/auth";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Icon,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import classes from "./ResetPassword.module.css";
import {createPortal} from "react-dom";
import LanguageContext from "../../../../contexts/LanguageContext";

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => setValue(e.target.value);
  return { value, onChange };
};

const usePasswordVisibility = () => {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(VisibilityOffIcon);
  const toggleVisibility = () => {
    if (type === "password") {
      setIcon(VisibilityIcon);
      setType("text");
    } else {
      setIcon(VisibilityOffIcon);
      setType("password");
    }
  };
  return { type, icon, toggleVisibility };
};

const ForgotPassword = ({ user, translation }) => {
  const oldPassword = useInput("");
  const newPassword = useInput("");
  const [message, setMessage] = useState("");
  const passwordVisibility = usePasswordVisibility();
  const [open, setOpen] = useState(false);

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);
  const updatePasswordString =
    language === "en_US" ? "Update Password" : "Mettre à jour le mot de passe";
  const oldPasswordString =
    language === "en_US" ? "Old Password" : "Ancien mot de passe";
  const newPasswordString =
    language === "en_US" ? "New Password" : "Nouveau mot de passe";
  const showPasswordString =
    language === "en_US" ? "Show Password" : "Afficher le mot de passe";

  async function handleUpdatePassword() {
    try {
      await updatePassword({
        oldPassword: oldPassword.value,
        newPassword: newPassword.value,
      });
      setMessage("Password updated successfully");
    } catch (err) {
      setMessage("Failed to update password");
    }
  }

  return (
    <>
      <Button
        className={classes.greyButton}
        variant="contained"
        size="small"
        sx={{ borderRadius: 1, minWidth: "50%" }}
        onClick={() => setOpen(true)}
      >
        {translation.button_reset}
      </Button>

      {createPortal(
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <Box component="form" noValidate autoComplete="off">
            <DialogContent>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {updatePasswordString}
              </Typography>
              <Box display="flex" flexDirection="column">
                <TextField
                  name="username"
                  autoComplete="username"
                  value={user.username.value}
                  onChange={user.username.onChange}
                  type="text"
                  hidden
                  style={{ display: "none" }}
                />
                <TextField
                  name="oldPassword"
                  label={oldPasswordString}
                  value={oldPassword.value}
                  onChange={oldPassword.onChange}
                  type={passwordVisibility.type}
                  sx={{ mt: 4, width: "350px" }}
                />
                <TextField
                  name="newPassword"
                  autoComplete="new-password"
                  label={newPasswordString}
                  value={newPassword.value}
                  onChange={newPassword.onChange}
                  type={passwordVisibility.type}
                  sx={{ mt: 4 }}
                />
              </Box>
              <Box display="flex" alignItems="center" sx={{ mt: 4 }}>
                <Typography variant="body2" color="textSecondary">
                  {showPasswordString}
                </Typography>
                <IconButton onClick={passwordVisibility.toggleVisibility}>
                  <Icon component={passwordVisibility.icon} />
                </IconButton>
              </Box>
              {message && <p>{message}</p>}
            </DialogContent>
            <DialogActions>
              <Button className={classes.button} onClick={() => setOpen(false)}>
                {translation.button_cancel}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleUpdatePassword}
                autoFocus
              >
                {translation.button_save}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>,
        document.body
      )}
    </>
  );
};

export default withAuthenticator(ForgotPassword);
