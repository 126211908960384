import { Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAdminPortalTranslation } from "../../api/translationApi";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const InviteMember = ({
  goBack = () => {},
  formData,
  setFormData,
  setFormComplete,
}) => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  const invites = 2;

  const formInputStyles = {
    bgcolor: "#F1F3F4",
    borderRadius: 2,
    "& .MuiFormHelperText-root": {
      color: "red",
      margin: 0,
      padding: 1,
      backgroundColor: "#fff",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormData(data);
    setFormComplete(true);
  };

  return (
    <div style={{ padding: 40 }}>
      <h2 style={{ fontWeight: "500" }}>
        {adminPortalTranslation?.inviteFriendsFamily}
      </h2>
      <p style={{ fontWeight: "500" }}>
        {adminPortalTranslation?.remainingInvitesOne}
        <b>{invites}</b>
        {adminPortalTranslation?.remainingInvitesTwo}
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 50,
        }}
      >
        <div>
          <Typography>{adminPortalTranslation?.profile_first}</Typography>
          <TextField
            fullWidth
            sx={formInputStyles}
            {...register("firstName", { required: "First name is required" })}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            defaultValue={formData?.firstName}
          />
        </div>
        <div>
          <Typography>{adminPortalTranslation?.profile_last}</Typography>
          <TextField
            fullWidth
            sx={formInputStyles}
            {...register("lastName", { required: "Last name is required" })}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            defaultValue={formData?.lastName}
          />
        </div>
        <div>
          <Typography>{adminPortalTranslation?.email}</Typography>
          <TextField
            fullWidth
            sx={formInputStyles}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
            defaultValue={formData?.email}
          />
        </div>
        <div>
          <Typography>{adminPortalTranslation?.message}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={5}
            sx={formInputStyles}
            {...register("message")}
            error={!!errors.message}
            helperText={errors.message?.message}
            defaultValue={formData?.message}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            sx={{ my: 4, borderRadius: 2 }}
            onClick={goBack}
          >
            {adminPortalTranslation?.back_button}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            sx={{ my: 4, borderRadius: 2 }}
            type="submit"
          >
            {adminPortalTranslation?.continue_button}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteMember;
