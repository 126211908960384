import {Outlet} from "react-router-dom";
import Navigation from "../components/navigation/Navigation";
import {Container} from "@mui/material";
import {useContext} from "react";
import UserContext from "../contexts/UserContexts";
import Footer from "../components/footer/Footer";
import "./Root.css";

const Root = () => {
  const { userData } = useContext(UserContext);
  return (
    <>
      <div className="root">
        <Navigation userData={userData} />
        <main className="main-content">
          <Container sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Root;
