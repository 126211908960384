import React, { useContext, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Title from "../title/Title";
import classes from "./CustomerForm.module.css";
import UserContext from "../../contexts/UserContexts";
import PhoneField from "../phone-field/PhoneField";
import { useAdminPortalTranslation } from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const CustomerForm = ({ method }) => {
  const { handleSubmit, control, register, reset } = useForm();
  const { businessId, customerId } = useParams();
  const [open, setOpen] = useState(false);
  const { jwtToken } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  const { selectedItem } = location.state || {};
  const editForm = method === "PUT";
  const storedItem = JSON.parse(localStorage.getItem("selectedItem"));
  const { t } = useInternalTranslation();
  const { adminPortalTranslation } = useAdminPortalTranslation();

  // TODO: This should be refactored to use the API
  const { language } = useContext(LanguageContext);

  const notAvailableString =
    language === "en_US" ? "Not Available" : "Non disponible";

  const backToString =
    language === "en_US" ? "Back to Customers" : "Retour aux clients";

  const [planStatus] = useState(selectedItem?.status || "Active");

  let url = `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${businessId}/members`;
  if (editForm) {
    url = `${url}/${customerId}`;
  }

  const formInputStyles = {
    bgcolor: "#F1F3F4",
    borderRadius: 2,
    "& .MuiFormHelperText-root": {
      color: "red",
      margin: 0,
      padding: 1,
      backgroundColor: "#fff",
    },
  };

  const onSubmit = async (data) => {
    //console.log("data:", data);

    const memberData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: String(data.phoneNumber),
      email: data.email,
      status: planStatus,
      memberLanguage: data.memberLanguage,
    };

    const options = {
      method: method,
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberData),
    };
    setOpen(false);
    const response = await fetch(url, options);

    if (!response.ok) {
      const errorData = await response.json();
      console.error(`Error: ${response.status} ${response.statusText}`);
      setErrorMessage(errorData.message || "Failed to submit form");
    } else {
      if (!editForm) reset();
      setOpen(true);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  let startDate = "";
  let endDate = "";
  if (editForm) {
    startDate = formatDate(selectedItem.productDetails?.startDate);
    endDate = formatDate(selectedItem.productDetails?.endDate);
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setErrorMessage(null);
    }, 250);
  }

  const action = (
    <>
      &nbsp; &nbsp; &nbsp;
      <Button
        color="secondary"
        size="small"
        sx={{
          color: "white",
        }}
        component={Link}
        to={`/businesses/${businessId}/customers`}
      >
        <u>{backToString}</u>
      </Button>
    </>
  );

  return (
    <>
      <Snackbar
        open={open || errorMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={!errorMessage ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {!!errorMessage && errorMessage}
          {!errorMessage &&
            editForm &&
            adminPortalTranslation["success_update_customer"]}
          {!errorMessage &&
            !editForm &&
            adminPortalTranslation["success_create_customer"]}
          {!errorMessage && action}
        </Alert>
      </Snackbar>
      <FormProvider>
        <form
          method={method}
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Grid container columnSpacing={8}>
            <Grid item xs={12} md={6}>
              {editForm ? (
                <Title>{adminPortalTranslation.edit_customer}</Title>
              ) : (
                <Title>{adminPortalTranslation.add_customer}</Title>
              )}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor={"firstName"}>
                    {adminPortalTranslation.business_first_name}
                  </InputLabel>
                  <Controller
                    name={"firstName"}
                    control={control}
                    defaultValue={selectedItem?.firstName}
                    value={selectedItem?.firstName}
                    rules={{
                      required: t("First Name is required"),
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id={"firstName"}
                        {...register("firstName")}
                        fullWidth
                        required
                        sx={formInputStyles}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel htmlFor={"lastName"}>
                    {adminPortalTranslation.business_last_name}
                  </InputLabel>
                  <Controller
                    name={"lastName"}
                    control={control}
                    defaultValue={selectedItem?.lastName}
                    value={selectedItem?.lastName}
                    rules={{
                      required: t("Last Name is required"),
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          id={"lastName"}
                          {...register("lastName")}
                          fullWidth
                          required
                          sx={formInputStyles}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
                {editForm && (
                  <Grid item xs={12}>
                    <InputLabel htmlFor={"username"}>
                      {adminPortalTranslation.profile_username}
                    </InputLabel>
                    <Controller
                      name={"username"}
                      control={control}
                      defaultValue={selectedItem?.userName}
                      value={selectedItem?.userName}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <TextField
                            id={"username"}
                            {...register("username")}
                            fullWidth
                            required
                            sx={formInputStyles}
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled
                            InputProps={{
                              inputProps: {
                                readOnly: true,
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PhoneField
                    gridSize={12}
                    control={control}
                    label={adminPortalTranslation.business_phone_number}
                    name="phoneNumber"
                    defaultValue={selectedItem?.phoneNumber}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor={"email"}>
                    {adminPortalTranslation.business_email_address}
                  </InputLabel>
                  <Controller
                    name={"email"}
                    control={control}
                    defaultValue={selectedItem?.email}
                    value={selectedItem?.email}
                    rules={{
                      required: t("Email is required"),
                      validate: (value) => {
                        const emailPattern =
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        return (
                          emailPattern.test(value) ||
                          t("Email has improper format")
                        );
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        disabled={method === "PUT"}
                        id={"email"}
                        type="email"
                        {...register("email")}
                        fullWidth
                        required
                        sx={formInputStyles}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor={"memberLanguage"}>
                    {adminPortalTranslation.language}
                  </InputLabel>
                  <Controller
                    name={"memberLanguage"}
                    control={control}
                    defaultValue={selectedItem?.memberLanguage || "en_US"}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        type="memberLanguage"
                        id="memberLanguage"
                        {...register("memberLanguage")}
                        fullWidth
                        sx={formInputStyles}
                        defaultValue={selectedItem?.memberLanguage || "en_US"}
                      >
                        <MenuItem value={"en_US"}>English (US)</MenuItem>
                        <MenuItem value={"fr_CA"}>Français</MenuItem>
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4" sx={{ mb: 3 }} gutterBottom>
                    {adminPortalTranslation.business_subscription_plan}
                  </Typography>

                  <Typography fontWeight={700} sx={{ mt: 4 }}>
                    {storedItem.productDetails[0].productName ||
                      notAvailableString}
                  </Typography>
                  {editForm && (
                    <>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography sx={{ mt: 4 }} fontWeight={700}>
                            {adminPortalTranslation.subscription_startdate}:
                          </Typography>
                          <Typography>{startDate}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ mt: 4 }} fontWeight={700}>
                            {adminPortalTranslation.subscription_enddate}:
                          </Typography>
                          <Typography>{endDate}</Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ mt: 4 }}>
                        <Grid item xs={6}>
                          <Typography>
                            {adminPortalTranslation.totalLicences}:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {selectedItem?.totalLicences || 0}
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 1 }}>
                          <Typography fontWeight={700}>
                            {adminPortalTranslation.availableLicences}:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 1 }}>
                          {selectedItem?.avaiableLicences || 0}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </Grid>
            </>
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={6}>
              <Button
                className={classes.button}
                component={Link}
                type="button"
                to={`/businesses/${businessId}/customers`}
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
              >
                {adminPortalTranslation.button_cancel || "Cancel"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
                type="submit"
              >
                {adminPortalTranslation.button_save || "Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default CustomerForm;
