import ProductForm from "../../components/product-form";
import {useAdminPortalTranslation} from "../../api/translationApi";

const AddProduct = () => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  return <ProductForm method="PUT" translation={adminPortalTranslation} />;
};

export default AddProduct;
