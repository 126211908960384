import React from "react";
import BusinessForm from "../../components/business-form";
import {useAdminPortalTranslation} from "../../api/translationApi";

const AddBusiness = () => {
  const { adminPortalTranslation } = useAdminPortalTranslation();

  return <BusinessForm method="POST" translation={adminPortalTranslation} />;
};

export default AddBusiness;
