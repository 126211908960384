import useSWR from "swr";

// url hardcoded for now
// const baseUrl = process.env.REACT_APP_MOCK_API_URL;
const baseUrl = process.env.REACT_APP_API_URL;

const domainsfetcher = async ([url, jwtToken]) => {
  if (!jwtToken) {
    return [];
  }

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch resource");
  }

  const data = await response.json();
  return data.data;
};

// custom SWR hook to fetch whitelists
export const useWhitelist = (jwtToken) => {
  const { data, error, isLoading, mutate } = useSWR(
    [baseUrl + "/whitelist/domains", jwtToken],
    domainsfetcher
  );

  return {
    whitelist: data ? data : [],
    isLoading: isLoading,
    error: error,
    mutate: mutate,
  };
};

// Function to post a new domain
export const postDomain = async (jwtToken, newDomain) => {
  try {
    const response = await fetch(baseUrl + "/whitelist/domains", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(newDomain),
    });

    if (response.status === 201) {
      return response.json();
    }
  } catch (error) {
    console.error("Failed to add new domain:", error);
    throw new Error("Failed to add new domain");
  }
};

// Function to upload a CSV file of domains and IP addresses
export const uploadDomainsCSV = async (jwtToken, newDomains) => {
  const response = await fetch(baseUrl + "/whitelist/domains/upload", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newDomains),
  });

  if (!response.ok) {
    throw new Error("Failed to upload CSV");
  }

  const data = await response.json();
  return data;
};

// function delete a domain
export const deleteDomain = async (jwtToken, domainName) => {
  const response = await fetch(baseUrl + "/whitelist/domains", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify({ domainName }),
  });

  if (!response.ok) {
    throw new Error("Failed to delete domain");
  }

  return;
};

// function update a domain
export const updateDomain = async (jwtToken, domainName, domain) => {
  const response = await fetch(baseUrl + "/whitelist/domains", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify({ domainName, domain }),
  });

  if (!response.ok) {
    throw new Error("Failed to update domain");
  }

  return;
};
