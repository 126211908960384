import React, {createContext, useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";

const UserContext = createContext();

const UserProvider = ({ children, jwtToken }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    if (jwtToken) {
      try {
        const decoded = jwtDecode(jwtToken);

        setUserGroups([...decoded[`cognito:groups`]]);
      } catch (err) {}
    }

    const fetchData = async () => {
      if (!jwtToken) {
        return;
      }
      const response = await fetch(
        `${process.env["REACT_APP_PORTAL_API_URL"]}/members/details`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data.data);
      setLoading(false);
    };

    fetchData();
  }, [jwtToken]);

  return (
    <UserContext.Provider value={{ data, loading, userGroups }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
