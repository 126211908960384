import {useState} from "react";
import terms from "./constants/TandCs.txt";
import termsFR from "./constants/TandCsFR.txt";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {useAdminPortalTranslation} from "../../api/translationApi";
import {getMemberDetails} from "../../api/memberApi";

const useTermsAndConditions = (jwtToken, onAccept = (response) => {}, onDecline = () => {}) => {

    const [policyText, setPolicyText] = useState("");
    const { adminPortalTranslation } = useAdminPortalTranslation();

    async function loadTerms(language) {
        if (!policyText) {
            const termsToFetch = language === "en_US" ? terms : termsFR;

            const response = await fetch(termsToFetch)
            if (response.status === 200) {
                const text = await response.text();
                setPolicyText(text);
            }
        } else {
            console.log("Terms already loaded");
        }
    }

    async function accept() {
        const url = `${process.env["REACT_APP_API_URL"]}/members/privacy`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + jwtToken,
            },
            body: JSON.stringify({ accepted: "true" }),
        });

        if (response.status === 200) {
            if (onAccept) {
                // Force resync of member details from API
                await getMemberDetails(jwtToken, true);
                onAccept(response);
            }
        }
    }

    function TermsAndConditions() {
        if (policyText) {
            return (
                <>
                <DialogContent>
        <pre
            style={{
                padding: "20px",
                fontSize: ".8rem",
                maxHeight: "500px",
                overflowX: "hidden",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                textAlign: "justify",
            }}
        >
          {policyText}
        </pre>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={onDecline}>
                        {adminPortalTranslation?.button_decline || "Decline"}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        onClick={accept}
                    >
                        {adminPortalTranslation?.button_accept || "Accept"}
                    </Button>
                </DialogActions>
                </>
            )
        }
    }

    return {
        accept,
        loadTerms,
        TermsAndConditions
    }
}

export default useTermsAndConditions;