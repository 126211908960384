import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useInternalTranslation from "./useInternalTranslation";

export const useFailedGoogleAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const { t } = useInternalTranslation();

  useEffect(() => {
    if (searchParams.has("error")) {
      setError(
        t("This email is already registered with another user.  Choose a different email")
      );
    } else {
      setError(null);
    }
  }, [searchParams]);

  const clearError = () => {
    setError(null);
    searchParams.delete("error");
    setSearchParams(searchParams);
  };

  return { error, clearError };
};
