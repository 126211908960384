//import react and router
import React, {useContext, useEffect, useMemo, useState} from "react";

// import material ui components
import {Box, Typography} from "@mui/material";

// import components
import ResourceCenterCard from "./ResourceCenterCard";

// import API
import {fetchContentful} from "../../../utils/api";

// import contexts
import LanguageContext from "../../../contexts/LanguageContext";

import "./ResourceCenter.css";

const ResourceCenter = () => {
  const [resourceCenter, setResourceCenter] = useState(null);
  const { language } = useContext(LanguageContext);

  const query = useMemo(() => {
    return `
  {
    resourceCenter(id: "7kPtP2JOaaDs6hPunh8rfY", locale: ${
      language === "en_US" ? '"en-US"' : '"fr-CA"'
    }) {
      title
      cardsCollection {
        items {
          icon {
            url
            title
          }
          iconAltText
          title
          description
          linkText
          linkHref
        }
      }
    }
  }
  `;
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchContentful(query);
        setResourceCenter(data.resourceCenter);
      } catch (error) {
        console.log("Failed", error);
      }
    };

    fetchData();
  }, [query]);

  if (!resourceCenter) {
    return "Loading...";
  }
  return (
    <>
      <Typography fontSize={29} fontWeight={700} marginBottom={2} marginTop={5}>
        {resourceCenter.title}
      </Typography>
      <Box className="cardContainer">
        {resourceCenter.cardsCollection?.items?.map((item, index) => (
          <ResourceCenterCard key={index} card={item} />
        )) || []}
      </Box>
    </>
  );
};

export default ResourceCenter;
