import googleStore from "../../assets/google-play-badge.png";
import appleStore from "../../assets/app-store-badge.svg";
import { Box } from "@mui/material";

const MobileApp = () => {
  return (
    <Box display="flex">
      <Box>
        <a href="https://apps.apple.com/us/app/knowscam-mobile/id6479377617">
          <img
            alt="Apple Store"
            style={{ paddingRight: "15px" }}
            width="150px"
            src={appleStore}
          />
        </a>
      </Box>
      <Box>
        <a href="https://play.google.com/store/apps/details?id=com.scamnetic.knowscam">
          <img alt="Google Store" height="43px" src={googleStore} />
        </a>
      </Box>
    </Box>
  );
};

export default MobileApp;
