function getAwsExports() {
  return {
    aws_project_region: process.env["REACT_APP_REGION"],
    aws_cognito_region: process.env["REACT_APP_REGION"],
    aws_user_pools_id: process.env["REACT_APP_USER_POOL"],
    aws_user_pools_web_client_id: process.env["REACT_APP_APP_CLIENT_ID"],
    oauth: {
      domain: process.env["REACT_APP_COGNITO_DOMAIN_URL"],
      scope: ["email", "openid"],
      redirectSignIn: process.env["REACT_APP_REDIRECT_SIGN_IN"],
      redirectSignOut: process.env["REACT_APP_REDIRECT_SIGN_OUT"],
      responseType: "token",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    REGION: process.env["REACT_APP_REGION"],
    USER_POOL_ID: process.env["REACT_APP_USER_POOL"],
    USER_POOL_APP_CLIENT_ID: process.env["REACT_APP_APP_CLIENT_ID"],
  }
}

export {
  getAwsExports
};
