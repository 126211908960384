import React from "react";
import "./GoogleButton.css";
// import { Button } from "@mui/material";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const GoogleButton = ({ onClick }) => {
  const { t } = useInternalTranslation();

  return (
    <button onClick={onClick} className="login-with-google-btn">
      {t("Sign in with Google")}
    </button>
  );
};

export default GoogleButton;
